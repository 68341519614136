@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'RobotoLight';
    src: local('RobotoLight'),
        url('./assets/fontfamily/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoRegular';
    src: local('RobotoRegular'),
        url('./assets/fontfamily/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoMedium';
    src: local('RobotoMedium'),
        url('./assets/fontfamily/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoBold';
    src: local('RobotoBold'),
        url('./assets/fontfamily/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'RobotoExtraBold';
    src: local('RobotoExtraBold'),
        url('./assets/fontfamily/Roboto-Black.ttf') format('truetype');
}

.Roboto-Light {
    font-family: RobotoLight, Arial, serif;
}

.Roboto-Regular {
    font-family: RobotoRegular, Arial, serif;
}

.Roboto-Medium {
    font-family: RobotoMedium, Arial, serif;
}

.Roboto-Bold {
    font-family: RobotoBold, Arial, serif;
}

.Roboto-ExtraBold {
    font-family: RobotoExtraBold, Arial, serif;
}

.btn-brown {
    @apply h-7 sm:h-8 md:h-10 border border-brown bg-brown text-white hover:bg-white hover:text-brown text-xs sm:text-sm  Roboto-Medium text-center duration-1000;
}

.btn-brown-outline {
    @apply h-7 sm:h-8 md:h-10 border border-brown hover:bg-brown hover:text-white bg-white text-brown text-xs sm:text-sm Roboto-Medium text-center duration-1000;
}

.btn-blue {
    @apply h-7 sm:h-8 md:h-10 border border-blue-base bg-blue-base text-white hover:bg-white hover:text-blue-base text-xs sm:text-sm  Roboto-Medium text-center duration-1000;
}

.btn-blue-outline {
    @apply h-7 sm:h-8 md:h-10 border border-blue-base hover:bg-blue-base hover:text-white bg-white text-blue-base text-xs sm:text-sm Roboto-Medium text-center duration-1000;
}

.page-padding{
    @apply px-5 sm:px-10 md:px-16 lg:px-20 xl:px-24
}
.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 80% !important;
    height: 100% !important;
}
.c-word-break {
    word-break: break-word;
    white-space: pre-wrap;      /* CSS3 */   
    white-space: -moz-pre-wrap; /* Firefox */    
    white-space: -pre-wrap;     /* Opera <7 */   
    white-space: -o-pre-wrap;   /* Opera 7 */    
}
.react-pdf__Page__textContent {
    display: none !important;
}
.responsive-table {
    max-width: 80vw;
    overflow: auto;
}
